import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    { 
      path: '*', 
      redirect: '/404' 
    },   
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue")
    },
    {
      path: "/transactions",
      name: "transactions",
      component: () => import("./views/Transactions"),
    },
    {
      path: "/stores",
      name: "stores",
      component: () => import("./views/Stores.vue"),
    },
    {
      path: "/facture",
      name: "facture",
      component: () => import("./views/Facture.vue"),
    },
    {
      path: "/result",
      name: "result",
      component: () => import("./views/Result.vue"),
    },
    {
      path: "/form",
      name: "form",
      component: () => import("./views/Form.vue"),
    },
    {
      path: "/play",
      name: "play",
      component: () => import("./views/Play.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
  },
    {
      path: "/dotations",
      name: "dotations",
      component: () => import("./views/Dotations.vue"),
    },
    {
      path: "/mentions",
      name: "mentions",
      component: () => import("./views/Mentions.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/login",
      name: "login",
      component: () => import("./views/Login.vue"),
    },
    {
      path: "/produits",
      name: "produits",
      component: () => import("./views/Produits.vue"),
    }
  ],
});

export default router;
